import { useRef, useState } from 'react'
import { KeyboardArrowDown } from '@mui/icons-material'
import {
  Box,
  Checkbox,
  Grid,
  ListItemText,
  MenuItem,
  Select,
  Typography,
  useTheme,
  useMediaQuery,
  TextField,
  TextFieldProps,
} from '@mui/material'
import { IValueLabel } from 'interfaces'
import { FormattedMessage, useIntl } from 'react-intl'
import {
  selectSelectedJobRole,
  setMyStaffJobRole,
  useAppDispatch,
  useAppSelector,
  getSelectedLanguage,
  selectSelectedStartDate,
  setMyStaffStartDate,
  selectSelectedEndDate,
  setMyStaffEndDate,
  selectUser,
} from 'store'

import {
  CheckedIcon,
  StyledSelectFormControl,
  UncheckedIcon,
} from 'components/StaffMembers/Filters/styles'

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { MobileDatePicker } from '@mui/x-date-pickers'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'

type ITextDateProps = {
  params: TextFieldProps
  id: string
  onClick?: () => void
  onClose?: () => void
}
interface JobRolesProps {
  jobRoles?: IValueLabel[]
}

const JobRoles = ({ jobRoles }: JobRolesProps) => {
  const { isoCode } = useAppSelector(getSelectedLanguage)
  const user = useAppSelector(selectUser)
  const selectedJobRole = useAppSelector(selectSelectedJobRole)
  const selectedStartDate = useAppSelector(selectSelectedStartDate)
  const selectedEndDate = useAppSelector(selectSelectedEndDate)
  const theme = useTheme()
  const isMedium = useMediaQuery(theme.breakpoints.down('md'))
  const intl = useIntl()

  const [open, setOpen] = useState(false)
  const [isOpen, setisOpen] = useState(false)

  const dispatch = useAppDispatch()

  const inputRef = useRef<HTMLDivElement>(null)
  const inputRef1 = useRef<HTMLDivElement>(null)

  const RenderDateInput = ({ onClick, onClose, params, id }: ITextDateProps) => {
    return (
      <>
        <TextField
          id={id}
          {...params}
          inputProps={{
            ...params.inputProps,
            placeholder: intl.formatMessage({
              id: `membersList.ExpiresOn.placeholder.${id}`,
            }),
          }}
          fullWidth
          onBlur={onClose}
          onFocus={onClick}
          onTouchStart={onClick}
          onClick={onClick}
        />
      </>
    )
  }

  return (
    <Grid item xs={12} sm={12} md={3} lg={3}>
      {jobRoles && jobRoles.length && (
        <StyledSelectFormControl
          size="small"
          variant="outlined"
          fullWidth
          // ToDo - make borderWidth on focus as 1px everywhere on customTheme
          sx={{
            '& .MuiOutlinedInput-notchedOutline': {
              border: '1px solid #C4C4C4 !important',
            },
          }}
        >
          <Typography color={theme.palette.grey[800]} gutterBottom>
            <FormattedMessage id="membersList.jobRole" />
          </Typography>
          <Select
            IconComponent={KeyboardArrowDown}
            displayEmpty
            id="job-role-select"
            value={selectedJobRole || []}
            size="small"
            fullWidth
            multiple
            style={{
              backgroundColor: '#fff',
            }}
            onChange={e => {
              const { value } = e.target
              dispatch(setMyStaffJobRole(value as string[]))
            }}
            renderValue={selected => {
              if (selected.length === 0) {
                return intl.formatMessage({
                  id: 'app.labels.select',
                })
              }
              const selectedValues = jobRoles.map(jobRole => {
                if (selected && selected.includes(jobRole.value as string)) {
                  return jobRole.label
                }
                return null
              })
              return selectedValues.filter(Boolean).join(', ')
            }}
          >
            {jobRoles.map(jobRole => (
              <MenuItem
                value={jobRole.value}
                key={jobRole.value}
                sx={{
                  gap: 0.5,
                  paddingInline: '0px',
                  paddingBlock: '2px',

                  '&.Mui-selected': {
                    backgroundColor: '#EBF1F6',
                  },
                }}
              >
                <Checkbox
                  icon={<UncheckedIcon />}
                  checkedIcon={<CheckedIcon />}
                  checked={
                    selectedJobRole &&
                    selectedJobRole.findIndex((item: string) => item === jobRole.value) >=
                      0
                  }
                  size="small"
                  disableRipple
                />
                <ListItemText
                  primary={jobRole.label}
                  primaryTypographyProps={{ variant: 'subtitle1' }}
                />
              </MenuItem>
            ))}
          </Select>
        </StyledSelectFormControl>
      )}

      <Box>
        <Typography color={theme.palette.grey[800]} gutterBottom marginTop={'8px'}>
          <FormattedMessage id="evLevel.expires" />
        </Typography>

        <Grid container spacing={1}>
          <Grid item xs={12} sm={6} md={12} lg={12}>
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={isoCode}>
              <Box display="flex" flexDirection="column" width="100%">
                {isMedium ? (
                  <MobileDatePicker
                    value={selectedStartDate}
                    onChange={newValue => {
                      dispatch(setMyStaffStartDate(new Date(newValue as Date)))
                    }}
                    renderInput={params => (
                      <RenderDateInput {...{ params, id: 'startDate' }} />
                    )}
                    showToolbar={false}
                    inputFormat={user!.dateTimeFormatCulture}
                  />
                ) : (
                  <DatePicker
                    inputFormat={user!.dateTimeFormatCulture}
                    value={selectedStartDate}
                    onChange={newValue => {
                      dispatch(setMyStaffStartDate(new Date(newValue as Date)))
                    }}
                    open={open}
                    onOpen={() => setOpen(true)}
                    onClose={() => setOpen(false)}
                    renderInput={params => (
                      <>
                        <RenderDateInput
                          {...{ params, id: 'startDate' }}
                          onClick={() => setOpen(true)}
                          onClose={() => setOpen(false)}
                        />
                        <Box ref={inputRef} sx={{ marginTop: '-5px' }} />
                      </>
                    )}
                    PopperProps={{
                      placement: 'bottom-start',
                      anchorEl: inputRef.current,
                    }}
                  />
                )}
              </Box>
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12} sm={6} md={12} lg={12}>
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={isoCode}>
              {isMedium ? (
                <MobileDatePicker
                  value={selectedEndDate}
                  onChange={newValue => {
                    dispatch(setMyStaffEndDate(new Date(newValue as Date)))
                  }}
                  renderInput={params => (
                    <RenderDateInput {...{ params, id: 'endDate' }} />
                  )}
                  showToolbar={false}
                  inputFormat={user!.dateTimeFormatCulture}
                />
              ) : (
                <DatePicker
                  inputFormat={user!.dateTimeFormatCulture}
                  value={selectedEndDate}
                  onChange={newValue => {
                    dispatch(setMyStaffEndDate(new Date(newValue as Date)))
                  }}
                  open={isOpen}
                  onOpen={() => setisOpen(true)}
                  onClose={() => setisOpen(false)}
                  renderInput={params => (
                    <>
                      <RenderDateInput
                        {...{ params, id: 'endDate' }}
                        onClick={() => setisOpen(true)}
                        onClose={() => setisOpen(false)}
                      />
                      <Box ref={inputRef1} sx={{ marginTop: '-5px' }} />
                    </>
                  )}
                  PopperProps={{
                    placement: 'bottom-start',
                    anchorEl: inputRef1.current,
                  }}
                />
              )}
            </LocalizationProvider>
          </Grid>
        </Grid>
      </Box>
    </Grid>
  )
}

export default JobRoles
