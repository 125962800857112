export function removeEmptyValues(obj: any) {
  return Object.keys(obj).reduce((acc, key) => {
    if (
      (Array.isArray(obj[key]) && obj[key].length === 0) ||
      obj[key] === '' ||
      obj[key] === null
    ) {
      return acc
    }
    return { ...acc, [key]: obj[key] }
  }, {})
}
