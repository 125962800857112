import { KeyboardArrowDown } from '@mui/icons-material'
import {
  Box,
  InputLabel,
  LinearProgress,
  MenuItem,
  Select,
  TableBody,
  TableCell,
  TableRow,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'

import { ISearchMyStaffResponse, IStaffMember } from 'interfaces'
import { FC, useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'

import { useDispatch } from 'react-redux'
import { selectTableConfig, setMyStaffTableConfig, useAppSelector } from 'store'

import RowCollapse from './RowCollapse'
import RowTable from './RowTable'
import {
  StyledHeaderMobile,
  StyledHeaderTable,
  StyledHeaderTableCell,
  StyledPagination,
  StyledTable,
  StyledTableContainerWrapper,
  StyledWrapperTableCollapse,
} from './styles'

type MembersListProps = ISearchMyStaffResponse['data'] & {
  isLoading: boolean
}

const MembersList: FC<MembersListProps> = ({
  records,
  isLoading,
  totalPages,
  totalRecords,
}) => {
  const theme = useTheme()
  const tableConfig = useAppSelector(selectTableConfig)
  const isAboveSmall = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'))
  const [rows, setRows] = useState<IStaffMember[]>([])
  const dispatch = useDispatch()

  const { page: currentPage, pageSize: resultsPerPage } = tableConfig

  const changeResultsPerPage = (e: any) => {
    dispatch(setMyStaffTableConfig({ page: 1, pageSize: Number(e.target.value) }))
  }

  const rowsPerPageOptions = [10, 25, 50, 100, 200]

  const limitsRowsCalc = resultsPerPage * currentPage
  const totalRecordsFind = totalRecords === 0 ? 1 : Number(totalRecords)
  const limitsRowsPerPage =
    limitsRowsCalc > totalRecordsFind ? totalRecordsFind : limitsRowsCalc

  const actualRows =
    records?.length === 0
      ? 1
      : currentPage === 1
      ? currentPage
      : totalPages === currentPage
      ? totalRecordsFind - (records!.length - 1)
      : resultsPerPage * (currentPage - 1) + 1

  useEffect(() => {
    if (records?.length) {
      const newRows = records!.reduce((acc: IStaffMember[], curr: IStaffMember) => {
        const newRecord = {
          ...curr,
          id: curr.staffMemberUUID,
        }

        return [...acc, newRecord]
      }, [])
      setRows(newRows)
    } else {
      setRows([])
    }
  }, [records])

  const RenderLoading = () => (
    <>
      {isLoading ? (
        <TableRow>
          <StyledHeaderTableCell colSpan={8} role="complementary">
            <LinearProgress variant="indeterminate" />
          </StyledHeaderTableCell>
        </TableRow>
      ) : null}
    </>
  )

  const hasResults = rows.length > 0

  return (
    <>
      {isAboveSmall ? (
        <>
          <StyledTableContainerWrapper>
            <StyledTable>
              <StyledHeaderTable>
                <TableRow>
                  <StyledHeaderTableCell>
                    <FormattedMessage id="membersList.pinName" />
                  </StyledHeaderTableCell>
                  <StyledHeaderTableCell>
                    <FormattedMessage id="membersList.jobRole" />
                  </StyledHeaderTableCell>
                  <StyledHeaderTableCell>
                    <FormattedMessage id="membersList.businessUnit" />
                  </StyledHeaderTableCell>
                  <StyledHeaderTableCell>
                    <FormattedMessage id="membersList.certifiedLevelAndExpire" />
                  </StyledHeaderTableCell>
                  <StyledHeaderTableCell>
                    <FormattedMessage id="applicationTerm.workingLevel" />
                  </StyledHeaderTableCell>
                  <StyledHeaderTableCell>
                    <FormattedMessage id="applicationTerm.targetLevel" />
                  </StyledHeaderTableCell>
                  <StyledHeaderTableCell></StyledHeaderTableCell>
                  <StyledHeaderTableCell></StyledHeaderTableCell>
                </TableRow>
              </StyledHeaderTable>
              <TableBody>
                <RenderLoading />
                {hasResults ? (
                  rows.map(row => {
                    return <RowTable key={row.staffMemberUUID} {...{ row }} />
                  })
                ) : (
                  <TableRow>
                    <TableCell colSpan={8}>
                      <Typography align="center" color={theme.palette.grey[700]}>
                        <FormattedMessage id="general.lists.noRows" />
                      </Typography>
                    </TableCell>
                  </TableRow>
                )}
                <RenderLoading />
              </TableBody>
            </StyledTable>
          </StyledTableContainerWrapper>

          {hasResults && (
            <>
              <Box sx={{ my: 2 }}>
                <Box display="flex" justifyContent="space-between">
                  <Box display="flex" alignItems="center" gap="5px">
                    <InputLabel id="results-per-page">
                      <FormattedMessage id="general.table.resultsPerPage" />
                    </InputLabel>
                    <Select
                      IconComponent={KeyboardArrowDown}
                      labelId="results-per-page"
                      id="results-per-page-select"
                      value={resultsPerPage}
                      onChange={changeResultsPerPage}
                      size="small"
                      sx={{
                        marginLeft: '6px',
                        borderRadius: '0px',
                        '& .MuiSelect-select': {
                          padding: '6px 12px',
                        },
                      }}
                    >
                      {rowsPerPageOptions.map(option => (
                        <MenuItem key={option} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </Select>
                  </Box>

                  <Typography
                    display="flex"
                    alignItems="center"
                    color={theme.palette.grey[700]}
                  >
                    <FormattedMessage
                      id="general.table.showingResults"
                      values={{
                        actualRows,
                        limitsRowsPerPage,
                        totalRecordsFind,
                      }}
                    />
                  </Typography>

                  <StyledPagination
                    color="primary"
                    variant="outlined"
                    page={currentPage}
                    count={totalPages}
                    onChange={(_, newPage) =>
                      dispatch(
                        setMyStaffTableConfig({
                          page: newPage,
                          pageSize: resultsPerPage,
                        }),
                      )
                    }
                  />
                </Box>
              </Box>
            </>
          )}
        </>
      ) : (
        <StyledWrapperTableCollapse>
          <StyledHeaderMobile>
            <Typography>
              <FormattedMessage id="staffMembers.page.title" />
            </Typography>
          </StyledHeaderMobile>
          {isLoading && <LinearProgress variant="indeterminate" sx={{ mt: '10px' }} />}
          {hasResults ? (
            rows.map(row => <RowCollapse key={row.staffMemberUUID} row={row} />)
          ) : (
            <Box display="flex" justifyContent="center" mt={2}>
              <Typography color={theme.palette.grey[700]}>
                <FormattedMessage id="general.lists.noRows" />
              </Typography>
            </Box>
          )}

          {isLoading && <LinearProgress variant="indeterminate" sx={{ mt: '10px' }} />}
          {hasResults && (
            <Box display="flex" flexDirection="column" alignItems="center" mt={2} gap={2}>
              <Typography color={theme.palette.grey[700]}>
                <FormattedMessage
                  id="general.table.showingResults"
                  values={{
                    actualRows,
                    limitsRowsPerPage,
                    totalRecordsFind,
                  }}
                />
              </Typography>
              <StyledPagination
                color="primary"
                variant="outlined"
                shape="rounded"
                page={currentPage}
                count={totalPages}
                onChange={(_, newPage) =>
                  dispatch(
                    setMyStaffTableConfig({ page: newPage, pageSize: resultsPerPage }),
                  )
                }
              />
            </Box>
          )}
        </StyledWrapperTableCollapse>
      )}
    </>
  )
}

export default MembersList
