// Redux business units feature slice to store all global user state related data.

import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IMyStaffState } from 'interfaces'
import { RootState } from 'store'

export const initialState: IMyStaffState = {
  filters: {
    certifiedLevel: [],
    workingLevel: [],
    targetLevel: [],
    actions: [],
  },
  jobRoles: [],
  searchString: '',
  startDate: null,
  endDate: null,
  table: {
    page: 1,
    pageSize: 10,
  },
}

const myStaffSlice = createSlice({
  name: 'myStaffSlice',
  initialState: initialState,
  reducers: {
    resetMyStaffFilters: () => initialState,
    setMyStaffFilters(
      state,
      action: PayloadAction<{ filter: keyof IMyStaffState['filters']; value: string }>,
    ) {
      const { filter, value } = action.payload
      const filterValue = state.filters[filter]
      if (filterValue) {
        if (filterValue.includes(value)) {
          state.filters[filter] = filterValue.filter(item => item !== value)
        } else {
          state.filters[filter] = [...filterValue, value]
        }
      } else {
        state.filters[filter] = [value]
      }
    },
    setMyStaffJobRole(state, action: PayloadAction<string[]>) {
      state.jobRoles = action.payload
    },
    setMyStaffSearchString(state, action: PayloadAction<string>) {
      state.searchString = action.payload
    },
    setMyStaffStartDate(state, action: PayloadAction<Date | null>) {
      state.startDate = action.payload
    },
    setMyStaffEndDate(state, action: PayloadAction<Date | null>) {
      state.endDate = action.payload
    },
    setMyStaffTableConfig(state, action: PayloadAction<IMyStaffState['table']>) {
      const { page, pageSize } = action.payload
      state.table.page = page
      state.table.pageSize = pageSize
    },
  },
})

export const {
  setMyStaffFilters,
  setMyStaffJobRole,
  setMyStaffSearchString,
  setMyStaffStartDate,
  setMyStaffEndDate,
  resetMyStaffFilters,
  setMyStaffTableConfig,
} = myStaffSlice.actions

export const selectTableConfig = (state: RootState) => state.myStaff.table
export const selectSelectedFilters = (state: RootState) => state.myStaff.filters
export const selectSelectedJobRole = (state: RootState) => state.myStaff.jobRoles
export const selectSelectedSearchString = (state: RootState) => state.myStaff.searchString
export const selectSelectedStartDate = (state: RootState) => state.myStaff.startDate
export const selectSelectedEndDate = (state: RootState) => state.myStaff.endDate

export default myStaffSlice.reducer
