import { Container } from '@mui/material'
import Body from 'components/common/Body'
import Filters from 'components/StaffMembers/Filters'
import MembersList from 'components/StaffMembers/MembersList'
import SubHeader from 'components/layout/SubHeader'
import ApplicationFooter from 'components/layout/ApplicationFooter'
import BusinessUnitSelector from 'components/common/BusinessUnitSelector'
import { ISearchMyStaffParams } from 'interfaces'
import { useEffect, useState } from 'react'
import dayjs from 'dayjs'
import { useParams, useSearchParams } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import {
  selectSelectedBusinessUnits,
  selectSelectedFilters,
  selectSelectedJobRole,
  selectSelectedSearchString,
  selectTableConfig,
  useAppSelector,
  useAppDispatch,
  setMyStaffSearchString,
  selectSelectedStartDate,
  selectSelectedEndDate,
} from 'store'
import { useLazySearchMyStaffQuery } from 'store/api'
import { removeEmptyValues } from 'utils/objectHandlers'

const StaffMembers = () => {
  const { id } = useParams()
  const [searchParams] = useSearchParams()
  const q = searchParams.get('q')
  const dispatch = useAppDispatch()

  const [shouldRefetch, setShouldRefetch] = useState(false)
  const selectedFilters = useAppSelector(selectSelectedFilters)
  const tableConfig = useAppSelector(selectTableConfig)
  const selectedJobRole = useAppSelector(selectSelectedJobRole)
  const selectedSearchString = useAppSelector(selectSelectedSearchString)
  const selectedStartDate = useAppSelector(selectSelectedStartDate)
  const selectedEndDate = useAppSelector(selectSelectedEndDate)
  const selectedBusinessUnits = useAppSelector(selectSelectedBusinessUnits)

  const selectedBusinesUnitsValues = selectedBusinessUnits.map(bu => bu.value)

  const myStaffFilters: ISearchMyStaffParams = {
    ...tableConfig,
    ...selectedFilters,
    searchTerm: selectedSearchString,
    jobRoles: selectedJobRole,
    businessUnits: selectedBusinesUnitsValues,
    startDate: selectedStartDate
      ? dayjs(new Date(selectedStartDate as Date)).format('YYYY-MM-DD')
      : null,
    endDate: selectedEndDate
      ? dayjs(new Date(selectedEndDate as Date)).format('YYYY-MM-DD')
      : null,
  }
  const [getMyStaff, { data: myStaffData, isFetching }] = useLazySearchMyStaffQuery()

  const handleSeachMyStaff = () => {
    try {
      getMyStaff(removeEmptyValues(myStaffFilters))
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    handleSeachMyStaff()
  }, [tableConfig, shouldRefetch])

  useEffect(() => {
    if (id || q) {
      dispatch(setMyStaffSearchString(q || id || ''))
      setShouldRefetch(!shouldRefetch)
    }
  }, [id, q])

  if (!myStaffData) return null
  const { records, totalPages, totalRecords } = myStaffData
  return (
    <>
      <Body>
        <SubHeader
          breadcrumbItems={[
            {
              text: <FormattedMessage id="app.labels.dashboard" />,
              href: '/dashboard',
            },
            {
              text: <FormattedMessage id="app.labels.myStaff" />,
              href: '/staff-members',
            },
          ]}
        >
          <BusinessUnitSelector
            setShouldRefetch={setShouldRefetch}
            shouldRefetch={shouldRefetch}
          />
        </SubHeader>
        <Filters setShouldRefetch={setShouldRefetch} shouldRefetch={shouldRefetch} />

        <Container>
          <MembersList
            isLoading={isFetching}
            records={records}
            totalPages={totalPages}
            totalRecords={totalRecords}
          />
        </Container>
      </Body>

      <ApplicationFooter />
    </>
  )
}

export default StaffMembers
